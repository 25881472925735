var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.modale_cat)?_c('AddCat',{staticStyle:{"z-index":"30"},attrs:{"color":"dark","centered":true,"size":"lg","closeOnBackdrop":true,"id_old":_vm.id_cat,"desc_old":_vm.edit_desc,"color_old":_vm.edit_color,"action":_vm.action},on:{"closeModalClick":function($event){return _vm.chiudi()},"salvaCat":function($event){return _vm.salva()}}}):_vm._e(),_c('div',{staticClass:"container mt-2 card_post",staticStyle:{"background-color":"white","border-radius":"0.3rem"}},[_c('div',{staticClass:"breadcrumbs"},[_c('CLink',{staticClass:"breadcrumbs_link",on:{"click":function($event){return _vm.back()}}},[_vm._v("Gestione Corsi")]),_vm._v(" > Gestione Categorie ")],1),_c('div',{staticClass:"justify-content-center"},[_c('h2',{staticClass:"pl-2 text-center"},[_vm._v("Categorie presenti")]),_c('div',{staticClass:"cover_box mb-3"},[_c('div',{staticStyle:{"background-color":"white"}},[_c('CDataTable',{ref:"tabella_cat",staticClass:"p-2",attrs:{"table-filter":{
            placeholder: 'Ricerca...',
            label: 'Ricerca nelle categorie:',
          },"no-items-view":{
              noResults: 'Nessun filtro appliato',
              noItems: 'Nessuna categoria trovata',
            },"fields":_vm.campi,"items":_vm.categorie,"hover":"","striped":""},scopedSlots:_vm._u([{key:"color",fn:function(ref){
            var item = ref.item;
return [_c('td',{staticClass:"py-3",staticStyle:{"text-align":"center !important"}},[_c('div',{staticClass:"riquadro_colore py-0 m-0",style:({ '--bgColor': item.color })})])]}},{key:"show_details",fn:function(ref){
            var item = ref.item;
return [_c('td',{staticClass:"py-2",staticStyle:{"text-align":"center !important"}},[_c('CButton',{staticClass:"ml-2",attrs:{"size":"sm","color":"warning"},on:{"click":function($event){return _vm.editCat(item)}}},[_vm._v(" Modifica ")])],1)]}}])})],1)]),_c('div',{staticClass:"container"},[_c('CButton',{staticClass:"ml-2",staticStyle:{"color":"white"},attrs:{"color":"primary"},on:{"click":function($event){_vm.modale_cat = true;
            _vm.action = 'add';}}},[_c('i',{staticClass:"fas fa-plus fa-2x"}),_c('br'),_vm._v("Aggiungi")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }